import React from "react";
import './Footer.scss';

import { useLanguage } from './LanguageContext';
import textsEN from './TextsEn';
import textsRU from './TextsRu';

function Footer() {

  const currentYear = new Date().getFullYear();
  const { language } = useLanguage();
  const texts = language === 'en' ? textsEN : textsRU;

  return (
      <>

      <footer className="footer">
        <h2 className="footer__subtitle">{texts.contact}</h2>
        <div className="footer__social-links">
          <p className="footer__text">
            <a href="https://t.me/demi4ev" target="_blank" rel="noreferrer" className="footer__link">Telegram</a>, <a href="https://www.behance.net/demichev" target="_blank" rel="noreferrer" className="footer__link">Behance</a>, <a href="https://www.linkedin.com/in/ilia-mishchenko" target="_blank" rel="noreferrer" className="footer__link">LinkedIn</a>, <a href="https://github.com/demi4ev" target="_blank" rel="noreferrer" className="footer__link">Github</a>, <a href="https://www.instagram.com/demi4ev/" target="_blank" rel="noreferrer" className="footer__link">Instagram</a>.
          </p>
          <p className="footer__text footer__text_grey">{texts.contactPlace}</p>
          <p className="footer__text footer__text_grey">© {currentYear} {texts.contactName}</p>
        </div>

      </footer>

      </>
  )
}

export default Footer;