import React from "react";
import './Education.scss';

import wnnblLogo from '../images/wnnbl.svg';
import webflowLogo from '../images/webflow.svg';
import yandexLogo from '../images/yandex.svg';
import servantesLogo from '../images/servantes.svg';

import { useLanguage } from './LanguageContext';
import textsEN from './TextsEn';
import textsRU from './TextsRu';

function Education() {

  const { language } = useLanguage();
  const texts = language === 'en' ? textsEN : textsRU;

    return (
        <>

          <section className="education">
            <div className='education__item'>
              <img className='education__img' src={wnnblLogo} alt="Wannabelike" />
              <h3 className='education__title' lang={language}>Rozov Wannabelike</h3>
              <p className='education__text lang={language}'>{texts.educationRozov}</p>
            </div>
            <div className='education__item'>
              <img className='education__img' src={webflowLogo} alt="Webflow" />
              <h3 className='education__title' lang={language}>Webflow Intensive course</h3>
              <p className='education__text' lang={language}>{texts.educationWebflow}</p>
            </div>
            <div className='education__item'>
              <img className='education__img' src={yandexLogo} alt="Yandex" />
              <h3 className='education__title' lang={language}>{texts.educationYandexHead}</h3>
              <p className='education__text' lang={language}>{texts.educationYandex}</p>
            </div>
            <div className='education__item'>
              <img className='education__img' src={servantesLogo} alt="Servantes" />
              <h3 className='education__title' lang={language}>Spain DELE A2 level</h3>
              <p className='education__text' lang={language}>{texts.educationSpain}</p>
            </div>
          </section>

        </>
    )
}

export default Education;