import React from 'react';
import './Main.scss';
import Footer from './Footer';
import Logos from './Logos';
import Education from './Education';
import Work from './Work';
import { useLanguage } from './LanguageContext';
import textsEN from './TextsEn';
import textsRU from './TextsRu';


function Main() {

  const { language } = useLanguage();
  const texts = language === 'en' ? textsEN : textsRU;

  return (
    <>

    <main className="main-page">


      <section className="content content_column">
        <h1 className="content__title" lang={language}>{texts.mainTitle}</h1>
        <div className='content__link-container'>
          <a href='https://demi4ev.notion.site/e51cd423969a4e02816336a3cf1dddd7?pvs=4' target="_blank" rel="noreferrer" className='content__link' lang={language}>{texts.cv}</a>
          <p className='content__text content__text_grey'>(Notion)</p>
        </div>
      </section>


      <section className="content">
        <h2 className="content__subtitle" lang={language}>{texts.experience}</h2>
        <p className="content__text" lang={language}>{texts.experienceText}</p>
      </section>

      <Logos />

      <section className="content">
        <h2 className="content__subtitle" lang={language}>{texts.developing}</h2>
        <p className="content__text" lang={language}>{texts.developingText}</p>
      </section>

      <section className="content content_flex-start">
        <h2 className="content__subtitle content__subtitle_mt" lang={language}>{texts.education}</h2>
        <Education />
      </section>

      <section className="content">
        <h2 className="content__subtitle content__subtitle_white" lang={language}>{texts.about}</h2>
        <p className="content__text" lang={language}>{texts.aboutText}</p>
      </section>

      <section className="content">
        <h2 className="content__subtitle" lang={language}>{texts.work}</h2>
        <Work />
      </section>

      <Footer />

      <a href="mailto:demi4ev@gmail.com" target="_blank" rel="noreferrer" className='apply' lang={language}><span>{texts.applyToday}</span></a>

    </main>

    </>
  )
}

export default Main;