import React from 'react';
import "./Logos.scss"

const LogosContext = require.context('../images/logos', false, /\.(svg)$/);
const logos = LogosContext.keys().map(LogosContext);

const Logos = () => {
  return (
    <section className='logos'>
      {logos.map((logo, index) => (
          <img className="logos__img" key={index} src={logo} alt={`Logo ${index}`} />
      ))}
    </section>
  );
};

export default Logos;


