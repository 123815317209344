const textsRU = {
  mainTitle: "Илья Мищенко. Я дизайнер интерфейсов с навыками в разработке и 15-летним опытом.",
  experience: "Опыт",
  cv: "CV Илья Мищенко",
  experienceText: "Я создавал веб-сайты для таких брендов как Levi's, Vans, Benetton, Crocs, New Balance, Timberland, Salomon, Peak Performance, Arc'teryx, Incanto, Wolford и других.",
  developing: "Разработка",
  developingText: "У меня есть опыт вёрстки с анимацией в Webflow. Кроме того, я умею верстать в HTML / CSS / JS, использовать LESS, SCSS, Webpack, React, NPM и Git.",

  education: "Образование",
  educationRozov: "Прошёл курс для мидл-дизайнеров от Миши Розова, Pinkman. Научился создавать графику в Cinema 4D.",
  educationWebflow: "Прошёл интенсив от Антона Шакирова, Method Zero. Научился семантично и с анимациями верстать в Webflow.",
  educationYandexHead: "Яндекс Практикум",
  educationYandex: "Я проходил курс веб-разработчик от Яндекс Практикум. Получил большой опыт веб-разработки с нуля.",
  educationSpain: "Изучал испанский язык в течение года, сдал эказмен DELE A2 (и экзамен B1 в школе).",

  about: "Обо мне",
  aboutText: "Мне нравится создавать дизайн и развивать его. Всегда внимательно слушаю клиента и задаю правильные вопросы, потому что верю, что вместе мы можем достичь отличных результатов.",

  work: "Проекты",
  chunkBoardsTitle: "ChunkBoards",
  chunkBoardsPosition: "UI-дизайнер, сооснователь",
  chunkBoardsDate: "Апрель 2023 — настоящее время",
  chunkBoardsText: "Мы сделали сервис для гибкого преподавания английского языка, который дает студентам больше возможностей и снижает нагрузку на преподавателей. Моя задача — разработка пользовательского интерфейса, улучшение пользовательского опыта и создание визуального стиля.",
  onewayTitle: "Студия Oneway",
  onewayPosition: "UI-дизайнер, тимлид фронтенда",
  onewayDate: "Январь 2014 — декабрь 2023",
  onewayText: "Дизайн сайтов, приложений, дашбордов, разработка интерфейсов, прототипирование проектов, разработка CJM. Создание дизайн систем для последующей поддержки. Разработка и принятие решений относительно стратегии компании. Управление командой разработчиков, контроль результатов. Контроль всех этапов создания сайтов, контроль качества и результата.",
  lomonosovTitle: "lomonosov.info",
  lomonosovPosition: "UI-дизайнер, менеджер продукта",
  lomonosovDate: "2022",
  lomonosovText: "Дизайн и разработка подробной и понятной инструкции как поступить в университет в России в 2022.",
  bmTitle: "Издательство Бизнес-медиа",
  bmPosition: "Дизайнер",
  bmDate: "Ноябрь 2009 — ноябрь 2013",
  bmText: "Разработка сетки, вёрстка и дизайн журналов издательства. Предпечатная потдготовка, подготовка макетов к печати. Сайты, интерфейсы, буклеты, календари, журналы.",
  vwTitle: "Агентство Very Well",
  vwPosition: "Дизайнер, арт-директор",
  vwDate: "Июль 2007 — ноябрь 2009",
  vwText: "Разработка сетки, вёрстка и дизайн журнала «ЖЖ». В рамках рекламного агентства разрабатывал рекламные концепции автомобильных брендов для автосалонов, печатную продукцию и интернет-банеры.",

  contact: "Контакты",
  contactPlace: "Испания, Сантандер",
  contactName: "Илья Мищенко",
  applyToday: "Написать мне"
};

export default textsRU;