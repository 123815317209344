import React from "react";
import './Work.scss';

import { useLanguage } from './LanguageContext';
import textsEN from './TextsEn';
import textsRU from './TextsRu';

function Work() {

  const { language } = useLanguage();
  const texts = language === 'en' ? textsEN : textsRU;

    return (
        <>

          <section className="work">
          <div className='work__item'>
              <h3 className='work__title' lang={language}>{texts.onewayTitle}</h3>
              <div className="work__text-container">
                <p className='work__text work__text_grey' lang={language}>{texts.onewayPosition}</p>
                <p className='work__text work__text_grey' lang={language}>{texts.onewayDate}</p>
                <p className='work__text'>{texts.onewayText}</p>
              </div>
              <a href="https://oneway.studio"  target="_blank" rel="noreferrer" className="work__link">oneway.studio</a>
            </div>
            <div className='work__item'>
              <h3 className='work__title' lang={language}>{texts.chunkBoardsTitle}</h3>
              <div className="work__text-container">
                <p className='work__text work__text_grey' lang={language}>{texts.chunkBoardsPosition}</p>
                <p className='work__text work__text_grey' lang={language}>{texts.chunkBoardsDate}</p>
                <p className='work__text' lang={language}>{texts.chunkBoardsText}</p>
              </div>
              <a href="https://chunkboards.com"  target="_blank" rel="noreferrer" className="work__link">chunkboards.com</a>
            </div>
            <div className='work__item'>
              <h3 className='work__title' lang={language}>{texts.lomonosovTitle}</h3>
              <div className="work__text-container">
                <p className='work__text work__text_grey' lang={language}>{texts.lomonosovPosition}</p>
                <p className='work__text work__text_grey' lang={language}>{texts.lomonosovDate}</p>
                <p className='work__text'>{texts.lomonosovText}</p>
              </div>
              <a href="https://lomonosovinfo.netlify.app/"  target="_blank" rel="noreferrer" className="work__link">lomonosovinfo.netlify.app</a>
            </div>
            <div className='work__item'>
              <h3 className='work__title' lang={language}>{texts.bmTitle}</h3>
              <div className="work__text-container">
                <p className='work__text work__text_grey' lang={language}>{texts.bmPosition}</p>
                <p className='work__text work__text_grey' lang={language}>{texts.bmDate}</p>
                <p className='work__text'>{texts.bmText}</p>
              </div>
              <a href="https://bismedia.me"  target="_blank" rel="noreferrer" className="work__link">bismedia.me</a>
            </div>
            <div className='work__item'>
              <h3 className='work__title' lang={language}>{texts.vwTitle}</h3>
              <div className="work__text-container">
                <p className='work__text work__text_grey' lang={language}>{texts.vwPosition}</p>
                <p className='work__text work__text_grey' lang={language}>{texts.vwDate}</p>
                <p className='work__text'>{texts.vwText}</p>
              </div>
            </div>
          </section>

        </>
    )
}

export default Work;