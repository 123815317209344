// LanguageToggle.js
import React, { useState } from 'react';
import './LanguageToggle.scss';
import { useLanguage } from './LanguageContext';

const LanguageToggle = () => {
  const { toggleLanguage, language } = useLanguage();
  const [isRussian, setIsRussian] = useState(language === 'ru');

  const toggleLanguageHandler = () => {
    const newLanguage = isRussian ? 'en' : 'ru';
    setIsRussian(!isRussian);
    toggleLanguage(newLanguage);
  };

  return (
    <button className="language-toggle__button" onClick={toggleLanguageHandler}>
      {isRussian ? 'En' : 'Ru'}
    </button>
  );
};

export default LanguageToggle;
