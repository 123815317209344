const textsEN = {
  mainTitle: "Ilia Mishchenko. I’m a UI‑designer with skills in development with 15 years experience.",
  experience: "Experience",
  cv: "CV Ilia Mishchenko",
  experienceText: "I designed websites for the Russian versions of Levi's, Vans, Benetton, Crocs, New Balance, Timberland, Salomon, Peak Performance, Arc'teryx, Incanto, Wolford and others.",
  developing: "Developing",
  developingText: "I have experience in creating animated markup using Webflow. Furthermore, I am proficient in a diverse set of tools and technologies, encompassing website layout, HTML/CSS, LESS, SCSS, Webpack, React, NPM, and Git.",

  education: "Education",
  educationRozov: "I completed the course for mid-level designers Include Cinema 4D by Misha Rozov, Pinkman.",
  educationWebflow: "I completed an intensive course on Webflow by Anton Shakirov, Method Zero.",
  educationYandexHead: "Yandex Practicum",
  educationYandex: "I completed the Yandex Practicum course for web development.",
  educationSpain: "I studied Spanish in Spain for a year, passed the DELE A2 exam (and B1 exam in school).",
  about: "About",
  aboutText: "I love creating designs and developing them. I always listen to the customer and ask the right questions because I believe that only together can we achieve great results.",

  work: "Recent work",
  chunkBoardsTitle: "ChunkBoards",
  chunkBoardsPosition: "UI-Designer, Сo-Owner",
  chunkBoardsDate: "April 2023 — Present",
  chunkBoardsText: "A tool for agile English language teaching that empowers students and reduces teachers' workload. I focus on designing its user interface, enhancing user experience, and developing visual styles.",
  onewayTitle: "Oneway Studio",
  onewayPosition: "UI-Designer, front-end teamlead",
  onewayDate: "January 2014 — December 2023",
  onewayText: "When I started, the studio was developing websites for local businesses. Thanks to our design, we immediately won a tender to develop the INCITY online store, and from that moment on, we entered the national market. During my tenure, the studio earned numerous national awards and rankings, becoming a significant player in the e-commerce market in Russia. Website, application, and dashboard design, interface development, project prototyping, Customer Journey Mapping (CJM) development.",
  lomonosovTitle: "lomonosov.info",
  lomonosovPosition: "UI-Designer, Сo-Owner",
  lomonosovDate: "2022",
  lomonosovText: "Design and develop a detailed and user-friendly guide on applying to a university in Russia in 2022.",
  bmTitle: "Business Media Publishing House",
  bmPosition: "Designer",
  bmDate: "November 2009 — November 2013",
  bmText: "Layout, design, and typesetting of publishing house's magazines. Prepress preparation and layout for printing. Websites, interfaces, booklets, calendars, and magazines.",
  vwTitle: "Very Well Advertising Agency",
  vwPosition: "Designer, Art Director",
  vwDate: "July 2007 — November 2009",
  vwText: "Layout, typesetting, and design of JJ-magazine. Later became the magazine's Art Director. Developed advertising concepts for car brands for car dealerships, printed materials, and internet banners.",

  contact: "Contacts",
  contactPlace: "Spain, Santander",
  contactName: "Ilia Mishchenko",
  applyToday: "Apply Today"
};

export default textsEN;
